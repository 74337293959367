import styles from './Tabs.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

interface TabsProps extends BasicStyledComponent {
  current: number
  onChange: (newTab: number) => void
  tabs: string[]
}

const Tabs: React.VFC<TabsProps> = ({ className, current, onChange, tabs }) => (
  <div className={clsx(styles.container, className)}>
    {tabs.map((item, index) => (
      <button
        type="button"
        key={index}
        onClick={() => onChange(index)}
        className={clsx(styles.tab, { [styles.current]: current === index })}
      >
        {item}
      </button>
    ))}
  </div>
)

export default Tabs
