/* eslint-disable react/jsx-no-bind */
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { ComponentType, Fragment } from 'react'
import { htmlReactParse } from 'shared-code/html'
import { bpToPx } from 'shared-code/responsive'
import PostCardRelated from 'shared-components/posts/PostCardRelated'
import PostRow from 'shared-components/posts/PostRow'
import PostRowTiny from 'shared-components/posts/PostRowTiny'
import type { InfiniteSwiperProps } from 'shared-components/service/InfiniteSwiper'
import LazyLoad from 'shared-components/service/LazyLoad'
import styleContent from 'shared-components/styles/content.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import BreadCrumbs from 'shared-components/ui/BreadCrumbs'
import CoupledInfinitePosts from 'shared-components/ui/CoupledInfinitePosts'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import NamedBlock from 'shared-components/ui/NamedBlock'
import RoundLink from 'shared-components/ui/RoundLink'
import SidebarLayout from 'shared-components/ui/SidebarLayout'
import { DSPostShortWithAuthor, DSTaxonomyProps, DSTopicPage } from 'shared-definitions/types'
import styles from './TopicTemplate.module.css'

const InfiniteSwiper = dynamic(
  () => import('shared-components/service/InfiniteSwiper')
) as ComponentType<InfiniteSwiperProps<DSPostShortWithAuthor>>

interface TopicTemplateProps {
  page: DSTopicPage
  loadTaxonomiedList: (
    page: number,
    perPage: number,
    taxonomies: DSTaxonomyProps[]
  ) => Promise<DSPostShortWithAuthor[]>
  leaderBoards: React.ReactNode[]
  incontentAds?: React.ReactNode[]
  generateIncontentAdPlacementIndex?: () => number
  sideBar: React.ReactNode
  stickySidebar?: boolean
}

const TopicTemplate: React.VFC<TopicTemplateProps> = ({
  page,
  loadTaxonomiedList,
  sideBar,
  leaderBoards,
  incontentAds,
  generateIncontentAdPlacementIndex,
  stickySidebar,
}) => (
  <div className={styleUtils.wrapper}>
    <div className={styles.hero}>
      <h1 className={styles.title}>{page.title}</h1>
      <BreadCrumbs list={page.breadcrumbs} className={styles.breadcrumbs} />
      {page.subTitle || page.html ? (
        <div className={styles.content}>
          <div className={styles.subTitle}>{page.subTitle}</div>
          <div className={clsx(styleContent.content, styles.text)}>{htmlReactParse(page.html)}</div>
        </div>
      ) : null}
    </div>
    {page.topics ? (
      <>
        <NamedBlock title={page.topics.title} className={styles.categoriesBlock}>
          <div className={styles.categoriesGrid}>
            {page.topics.items.map((item, index) => (
              <RoundLink key={index} {...item} />
            ))}
          </div>
        </NamedBlock>
        {leaderBoards[0]}
      </>
    ) : null}
    {page.categories.length ? (
      <>
        {page.categories.map((item, index) => (
          <Fragment key={index}>
            <h2 className={styles.categoryTitle}>{item.title}</h2>
            <LazyLoad layout="raw" className={clsx(styles.lazy, styleUtils.mobileFull)}>
              {() => (
                <InfiniteSwiper
                  hideArrows={item.posts.totalItems <= 5}
                  className={styles.slider}
                  spaceBetween={10}
                  slidesPerView={1.1}
                  slidesOffsetBefore={15}
                  slidesOffsetAfter={15}
                  breakpoints={{
                    [bpToPx('sm')]: {
                      slidesPerView: 2.1,
                      slidesOffsetBefore: 5,
                      slidesOffsetAfter: 5,
                    },
                    [bpToPx('lg')]: {
                      slidesPerView: 3.1,
                      slidesOffsetBefore: 5,
                      slidesOffsetAfter: 5,
                    },
                    [bpToPx('xl')]: {
                      slidesPerView: 4.4,
                      spaceBetween: 20,
                      slidesOffsetBefore: 5,
                      slidesOffsetAfter: 5,
                    },
                  }}
                  slideClassName={styles.slide}
                  initial={item.posts.items}
                  render={renderItem => (
                    <PostCardRelated unresponsive post={renderItem} className={clsx(styles.card)} />
                  )}
                  loadMore={p => loadTaxonomiedList(p, item.posts.perPage, item.posts.taxonomies)}
                />
              )}
            </LazyLoad>
          </Fragment>
        ))}
        {leaderBoards[1]}
      </>
    ) : null}
    {page.news ? (
      <CoupledInfinitePosts
        className={styles.bottom}
        left={page.latest}
        right={page.news}
        renderLeft={item => <PostRow post={item} />}
        renderRight={item => <PostRowTiny mobileView post={item} className={styles.postTiny} />}
        loadLeft={(p, pP) => loadTaxonomiedList(p, pP, page.latest.items.taxonomies)}
        loadRight={(p, pP) => {
          if (!page.news) {
            return Promise.resolve([])
          }
          return loadTaxonomiedList(p, pP, page.news.items.taxonomies)
        }}
      />
    ) : null}
    {!page.news ? (
      <>
        <h2 className={clsx(styles.bottom, styles.categoryTitle)}>{page.latest.title}</h2>
        <SidebarLayout
          content={
            <LoadMoreList
              autoload={Infinity}
              hideButton
              render={(item: DSPostShortWithAuthor, index) => (
                <>
                  <PostRow key={index} post={item} large />
                  {incontentAds &&
                    generateIncontentAdPlacementIndex &&
                    (index + 1) % 5 === 0 &&
                    incontentAds[generateIncontentAdPlacementIndex()]}
                </>
              )}
              className={styles.list}
              initial={page.latest.items}
              loader={(p, pP) => loadTaxonomiedList(p, pP, page.latest.items.taxonomies)}
            />
          }
          sidebar={
            <div className={clsx(styles.sidebar, { [styles.stickySideBarAd]: stickySidebar })}>
              {sideBar}
            </div>
          }
        />
      </>
    ) : null}
  </div>
)

export default TopicTemplate
