/* eslint-disable react/function-component-definition */
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import styles from './CoupledInfinitePosts.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSDoubleNamed } from 'shared-definitions/types'
import Tabs from 'shared-components/ui/Tabs'
import { useState } from 'react'

interface CoupledInfinitePostsProps<T> extends BasicStyledComponent {
  left: DSDoubleNamed<T>
  right: DSDoubleNamed<T>
  renderLeft: (item: T, index: number) => React.ReactNode
  renderRight: (item: T, index: number) => React.ReactNode
  loadLeft: (page: number, perPage: number) => Promise<T[]>
  loadRight: (page: number, perPage: number) => Promise<T[]>
}

function CoupledInfinitePosts<T>({
  left,
  right,
  renderLeft,
  renderRight,
  loadLeft,
  loadRight,
  className,
}: CoupledInfinitePostsProps<T>): React.ReactElement {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <div className={clsx(className)}>
      <Tabs
        className={styles.tabs}
        tabs={[left.shortTitle, right.shortTitle]}
        current={currentTab}
        onChange={setCurrentTab}
      />
      <div className={styles.grid}>
        <h2 className={styles.title}>{left.title}</h2>
        <h2 className={styles.title}>{right.title}</h2>
        <LoadMoreList
          autoload={Infinity}
          hideButton
          render={renderLeft}
          className={clsx(styles.leftList, styles.list, { [styles.listActive]: currentTab === 0 })}
          initial={left.items}
          loader={loadLeft}
        />
        <LoadMoreList
          autoload={Infinity}
          hideButton
          render={renderRight}
          className={clsx(styles.rightList, styles.list, { [styles.listActive]: currentTab === 1 })}
          initial={right.items}
          loader={loadRight}
        />
      </div>
    </div>
  )
}

export default CoupledInfinitePosts
