import styles from './RoundLink.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSLabeledLink } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import ArrowTail from 'shared-svg/ArrowRightBird.svg'

type RoundLinkProps = BasicStyledComponent & DSLabeledLink

const RoundLink: React.VFC<RoundLinkProps> = ({ className, label, pLink }) => (
  <Linked className={clsx(styles.container, className)} {...pLink}>
    <span className={styles.label}>{label}</span>
    <ArrowTail className={styles.icon} />
  </Linked>
)

export default RoundLink
