import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './NamedBlock.module.css'

interface NamedBlockProps extends BasicStyledComponent {
  title: string
  hideMobileTitle?: boolean
  children: React.ReactNode | React.ReactNode[]
}

const NamedBlock: React.VFC<NamedBlockProps> = ({
  className,
  title,
  children,
  hideMobileTitle,
}) => (
  <div className={clsx(styles.container, className)}>
    <div className={clsx(styles.title, { [styles.hiddenMobile]: hideMobileTitle })}>{title}</div>
    {children}
  </div>
)

export default NamedBlock
