import { DSTopicPage } from 'shared-definitions/types'
import TopicTemplate from 'shared-components/templates/TopicTemplate'
import { getds } from 'ds'
import PlaywireTagged from 'shared-components/ad/PlaywireTagged'
import styles from './TopicTemplateAA.module.css'
import styleUtils from 'shared-components/styles/util.module.css'

interface TopicTemplateAAProps {
  page: DSTopicPage
}

const TopicTemplateAA: React.VFC<TopicTemplateAAProps> = ({ page }) => (
  <TopicTemplate
    page={page}
    loadTaxonomiedList={async (...args) => {
      const dataSource = await getds()
      const result = await dataSource.getTaxonomiedPostsList(...args)
      return result.data.items
    }}
    leaderBoards={[
      <PlaywireTagged key={0} type="leaderboard_atf" className={styles.leaderboardFirst} />,
      <PlaywireTagged key={1} type="leaderboard_btf" className={styles.leaderboardSecond} />,
    ]}
    sideBar={<PlaywireTagged className={styleUtils.sidebarEl} type="med_rect_atf" />}
  />
)

export default TopicTemplateAA
